@use '@adnova/jf-ng-components/config' as *;
@use '@angular/material' as mat;
@use '@angular/material/theming' as *;

@include mat.core();

@layer material, base;

$jf-primary: (
  50: #e7f6e8,
  100: #c6e8c7,
  200: #a2d9a3,
  300: $color-primary-light,
  400: #5dc061,
  500: #3db444,
  600: #34a53b,
  700: $color-primary,
  800: $color-primary-dark,
  900: rgb(0, 99, 16),
  A100: #c6e8c7,
  A200: #a2d9a3,
  A400: #5dc061,
  A700: #279330,
 contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$jf-secondary: (
  50: #fcf2e1,
  100: $color-secondary-light,
  200: #f4ca82,
  300: #f0b451,
  400: #eda42f,
  500: #eb9616,
  600: $color-secondary,
  700: #e17c0f,
  800: #db6d0b,
  900: #d05608,
  A100: #f8deb3,
  A200: #f4ca82,
  A400: #eda42f,
  A700: $color-secondary-dark,
  hover: #b4710d,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$jf-red: (
  50: #ffebef,
  100: #ffcdd3,
  200: #ff9999,
  300: #f87172,
  400: #ff4f4e,
  500: #ff3e31,
  600: #f83432,
  700: $color-error,
  800: #d82025,
  900: #c81118,
  A100: #ffcdd3,
  A200: #ff9999,
  A400: #ff4f4e,
  A700: #e5282c,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$app-primary: mat.define-palette($jf-primary, 700, 300, 900);
$app-accent: mat.define-palette($jf-secondary, 600, 100, 700);
$app-error: mat.define-palette($jf-red, 200, 700);

$app-theme: mat.define-light-theme((
 color: (
   primary: $app-primary,
   accent: $app-accent,
   error: $app-accent,
 ),
 density: 0,
));

@layer material {
  @include mat.all-component-themes($app-theme);
}


.mdc-checkbox__checkmark {
  color: #fff !important;
}

.mat-mdc-raised-button {
  box-shadow: none !important;
}