@use '@adnova/jf-ng-components/config' as *;
@use '@adnova/jf-ng-components/lib';

/** INFO
  * Global Styles and Overrides are set in this file.
  * Pls consider, if you need to add a global style, or if it should be added to a component.
  * this file is for global styles that are only required in the app and cannot be outsourced to the component library.
*/

body {
  /** INFO
    * Dieser Part muss für die Betriebsauswahl zusätzlich gesetzt werden.
    * Overflow muss dann in main stattfinden.
  */
  height: 100%; // INFO: Muss zusätzlich gesetzt werden, damit die Betriebsauswahl angezeigt wird.
  max-height: 100vh;
  min-height: -webkit-fill-available;
}

@include lib.app-preset;


.dialog-h1 {
  color: $color-neutrals-900;
  font-size: $font-size-m1;
}


